import React, { useEffect } from 'react'
import 'templates/base/helpers/functions'
import { Provider } from 'react-redux'
import { ConfigProvider } from 'antd'
import { I18nextProvider } from 'react-i18next'
import { ParallaxProvider } from 'react-scroll-parallax';
import { HelmetProvider } from 'react-helmet-async'
import brasil from 'antd/lib/locale/pt_BR'
import { PersistGate } from 'redux-persist/integration/react'
import Loadable from 'react-loadable'
import Loader from 'admin/painel/inc/Loader'
import HttpsRedirect from 'react-https-redirect';
import './theme/app.css'
import i18n from './i18n';
import { authenticate } from './modules/app.module'
import store from './utils/store'

const Router = Loadable({ loader: () => import('./routes'), loading: () => <Loader /> })

export default () => {
  useEffect(() => {
    store.store.dispatch(authenticate())

    document.addEventListener('keydown', e => {
      if (e.ctrlKey && e.key === 's') {
        e.preventDefault();
      }
    });
  }, [])

  return (
    <HttpsRedirect>
      <ParallaxProvider>
        <I18nextProvider i18n={i18n}>
          <HelmetProvider>
            <Provider store={store.store}>
              <PersistGate loading={null} persistor={store.persistor}>
                <ConfigProvider locale={brasil}>
                  <Router />
                </ConfigProvider>
              </PersistGate>
            </Provider>
          </HelmetProvider>
        </I18nextProvider>
      </ParallaxProvider>
    </HttpsRedirect>
  )
}
