import { createAction } from 'redux-act';
import { createPromiseAction } from '@adobe/redux-saga-promise'

export const setContent = createAction('WEBSITE_SET_CONTENT');
export const setContentDefault = createAction('WEBSITE_SET_CONTENT_DEFAULT');
export const setTitle = createAction('WEBSITE_SET_TITLE');
export const setConfiguracoes = createPromiseAction('WEBSITE_SET_CONFIG');
export const setConfiguracoesDefault = createPromiseAction('WEBSITE_SET_CONFIG_DEFAULT');
export const setMenu = createPromiseAction('WEBSITE_SET_MENU_CURRENT');
export const setIsHome = createPromiseAction('WEBSITE_SET_IS_HOME');
