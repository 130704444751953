import { createReducer } from 'redux-act';
import * as actions from './actions';

export default createReducer({
  [actions.setTitle]: (state, payload) => ({ ...state, title: payload }),
  [actions.setPath01]: (state, payload) => ({ ...state, path_01_url: payload[0], path_01: payload[1] }),
  [actions.setPath02]: (state, payload) => ({ ...state, path_02: payload }),
  [actions.setModulo]: (state, payload) => ({ ...state, modulo: payload }),
  [actions.setSubModulo]: (state, payload) => ({ ...state, subtitle: payload }),
  [actions.getTitle]: (state) => state.title,
  [actions.setCacheExpire]: (state, payload) => ({ ...state, last_modified: payload }),
  [actions.setErrorActive]: (state) => ({ ...state, has_error: true }),
  [actions.setErrorInactive]: (state) => ({ ...state, has_error: false }),
  [actions.setErrorData]: (state, payload) => ({ ...state, error: payload }),

  [actions.initCompressionImage]: (state, payload) => ({ ...state, compression_image: payload }),
  [actions.processingCompressionImage]: (state) => ({ ...state, compression_status: true }),
  [actions.processedCompressionImage]: (state, payload) => ({ ...state, compression_return: payload }),
  [actions.terminateCompressionImage]: (state) => ({ ...state, compression_status: false }),
}, {
  title: 'Carregando...',
  subtitle: 'Módulo',
  path_01_url: '/',
  path_01: '...',
  path_02: '...',
  modulo: '',
  last_modified: false,
  has_error: false,
  error: {},
  compression_status: false,
  compression_image: '',
  compression_return: ''
});
