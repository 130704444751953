import React from 'react';
import { Trans } from 'react-i18next';
import { Tag, Space, Divider, Spin } from 'antd';
import { configs } from 'theme';
import { Link } from 'react-router-dom';
import { generatePath } from "react-router";
import { sortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import getColumnSearchProps from 'admin/painel/inc/helpers/columnSearch'
import Loadable from 'react-loadable'

const Delete = Loadable({ loader: () => import('admin/painel/modulos/base/pages/Delete'), loading: () => <Spin /> })

let __NAMASPACE_ = 'depoimentos';

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
));

const config = {
  path: __NAMASPACE_,
  full_path: `${configs.painel_path}/${__NAMASPACE_}/`,
  title: <Trans i18nKey={`${__NAMASPACE_}:MODULE`} />,
  db: __NAMASPACE_,
  fields: [
    { name: 'id', label: <Trans i18nKey={`${__NAMASPACE_}:FIELDS_01`} />, type: 'id' },
    { name: 'title', label: <Trans i18nKey={`${__NAMASPACE_}:FIELDS_02`} />, type: 'text', validations: [{ required: true, message: <Trans i18nKey={`common:INPUT_REQUIRED`} /> }] },
    { name: 'text', label: <Trans i18nKey={`${__NAMASPACE_}:FIELDS_04`} />, type: 'textarea', validations: [{ required: true, message: <Trans i18nKey={`common:INPUT_REQUIRED`} /> }]},
    { name: 'status', label: <Trans i18nKey={`${__NAMASPACE_}:FIELDS_09`} />, type: 'switch' },
  ],
  columns: [
    {
      title: <MenuOutlined style={{ color: '#999' }} />,
      dataIndex: 'sort',
      width: 40,
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: <Trans i18nKey={`${__NAMASPACE_}:FIELDS_02`} />,
      dataIndex: 'title',
      key: 'title',
      ellipsis: true,
      ...getColumnSearchProps('title', __NAMASPACE_)
    },
    {
      title:<Trans i18nKey={`${__NAMASPACE_}:FIELDS_09`} />,
      key: 'status',
      dataIndex: 'status',
      filters: [
        { text: <Trans i18nKey={'common:ACTIVE'} />, value: true },
        { text: <Trans i18nKey={'common:INACTIVE'} />, value: false },
      ],
      //filteredValue: filteredInfo.name || null,
      onFilter: (value, record) => record.name.includes(value),
      sorter: (a, b) => a.name.length - b.name.length,
      //sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
      render: status => (
        <>
          {status
            ? <Tag color="green">
                <Trans i18nKey={'common:ACTIVE'} />
              </Tag>
            : <Tag color="yellow">
              <Trans i18nKey={'common:INACTIVE'} />
            </Tag>
          }
        </>
      ),
    },
    {
      title: <Trans i18nKey={'common:COLUMN_OPTIONS'} />,
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Link to={generatePath(`${configs.painel_path}/${__NAMASPACE_}/editar/:uuid`, { uuid: record.uid })}><Trans i18nKey={'common:COLUMN_EDIT'} /></Link>
          <Divider type="vertical" />
          <Link to={generatePath(`${configs.painel_path}/${__NAMASPACE_}/visualizar/:uuid`, { uuid: record.uid })}><Trans i18nKey={'common:COLUMN_SHOW'} /></Link>
          <Divider type="vertical" />
          <Delete id={record.uid} db={__NAMASPACE_} />
        </Space>
      ),
    },
  ]
}

export default config
