const React = require('react');
const nl2br = require('react-nl2br');
const classNames = require('classnames');
const memoize = require('fast-memoize')

window.memoize = memoize
window.nl2br = nl2br
window.classNames = classNames

function currentYPosition() {
  // Firefox, Chrome, Opera, Safari
  if (window.pageYOffset) return window.pageYOffset;
  // Internet Explorer 6 - standards mode
  if (document.documentElement && document.documentElement.scrollTop)
      return document.documentElement.scrollTop;
  // Internet Explorer 6, 7 and 8
  if (document.body.scrollTop) return document.body.scrollTop;
  return 0;
}

const toggleClass = (el, className) => el.classList.toggle(className);

function elmYPosition(eID) {
  var elm = document.getElementById(eID);

  window.$( `.active` ).removeClass( "active" )

  var y = elm.offsetTop;
  var node = elm;
  while (node.offsetParent && node.offsetParent != document.body) {
      node = node.offsetParent;
      y += node.offsetTop;
  } return y;
}

function smoothScroll(eID) {
  var startY = currentYPosition();
  var stopY = elmYPosition(eID);
  var distance = stopY > startY ? stopY - startY : startY - stopY;
  if (distance < 100) {
    window.scrollTo(0, stopY); return;
  }
  var speed = Math.round(distance / 100);
  if (speed >= 20) speed = 20;
  var step = Math.round(distance / 25);
  var leapY = stopY > startY ? startY + step : startY - step;
  var timer = 0;
  if (stopY > startY) {
      for ( var i=startY; i<stopY; i+=step ) {
          setTimeout("window.scrollTo(0, "+leapY+")", timer * speed);
          leapY += step; if (leapY > stopY) leapY = stopY; timer++;
      } return;
  }
  for ( var i=startY; i>stopY; i-=step ) {
      setTimeout("window.scrollTo(0, "+leapY+")", timer * speed);
      leapY -= step; if (leapY < stopY) leapY = stopY; timer++;
  }
return false;
}

window.EPPZScrollTo = smoothScroll

window.css_breakpoint = data => {
  let printer = {};

  for (var [key, value] of Object.entries(data)) {
    if(window._.has(data, 'a')) {
      printer = {
        ...printer,
      }
    }
  }
}

window.closestTo = (arr, key, direction) => {
  var offset_index = (direction === 'prev') ? -1 : 1;

  // Convert to integers
  var intarr = arr.map(function(x) {
      return parseInt(x, 10);
  });

  return intarr[(intarr.length + intarr.indexOf(key) + offset_index) % intarr.length];
}

window.searchInArray = window.memoize((searchQuery, array, objectKey=null) => {
  return array.filter(d=>{
      let data =objectKey? d[objectKey] : d //Incase If It's Array Of Objects.
      let dataWords= typeof data=="string" && data?.split(" ")?.map(b=>b&&b.toLowerCase().trim()).filter(b=>b)
      let searchWords = typeof searchQuery=="string"&&searchQuery?.split(" ").map(b=>b&&b.toLowerCase().trim()).filter(b=>b)

     let matchingWords = searchWords.filter(word=>dataWords.includes(word))

    return matchingWords.length

  })
})

window.get_route = window.memoize((locale, target) => {
  const routes = require(`templates/${process.env.REACT_APP_THEME}/i18n/routes`).default;

  return routes[locale][target];
})

window.route = window.memoize((target) => {
  const routes = require(`templates/${process.env.REACT_APP_THEME}/i18n/routes`).default;
  const locale = sessionStorage.getItem('language-db-frontend') === null
    ? "pt-BR"
    : sessionStorage.getItem('language-db-frontend').replace(/['"]+/g, '');

  return routes[locale][target];
})


window.LightenDarkenColor = (col, amt) => {

  let usePound = false;

  if (col[0] == "#") {
      col = col.slice(1);
      usePound = true;
  }

  const num = parseInt(col,16);

  let r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if  (r < 0) r = 0;

  let b = ((num >> 8) & 0x00FF) + amt;

  if (b > 255) b = 255;
  else if  (b < 0) b = 0;

  let g = (num & 0x0000FF) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);

}