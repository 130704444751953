export default {
    'pt-BR': {
        home: '/',
        sobre: 'sobre-nos',
        manutencao: 'manutencao',
        contato: 'contato',
        produtos: 'produtos',
        produto: 'produto',
        pecas: 'pecas',
        peca: 'peca',
        blog: 'blog',
        post: 'post',
        tag: 'tag',
        busca: 'busca',
        categoria: 'categoria',
        privacidade: 'privacidade'
    },
    'en-US': {
      home: '/',
      sobre: 'about-us',
      manutencao: 'maintenance',
      contato: 'contact',
      produtos: 'products',
      produto: 'product',
      pecas: 'pieces',
      peca: 'piece',
      blog: 'blog',
      post: 'post',
      tag: 'tag',
      busca: 'search',
      categoria: 'category',
      privacidade: 'privacy'
    },
    'en-UK': {
      home: '/',
      sobre: 'about-us',
      manutencao: 'maintenance',
      contato: 'contact',
      produtos: 'products',
      produto: 'product',
      pecas: 'pieces',
      peca: 'piece',
      blog: 'blog',
      post: 'post',
      tag: 'tag',
      busca: 'search',
      categoria: 'category',
      privacidade: 'privacy'
    },
    'es-ES': {
        home: '/',
        sobre: 'sobre-nos',
        manutencao: 'manutencao',
        contato: 'contato',
        produtos: 'produtos',
        produto: 'produto',
        pecas: 'pecas',
        peca: 'peca',
        blog: 'blog',
        post: 'post',
        tag: 'tag',
        busca: 'busca',
        categoria: 'categoria',
        privacidade: 'privacidade'
    }
}
