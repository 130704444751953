
import React from 'react'
import ReactDOM from 'react-dom'
import shortid from  "shortid";
import moment from 'moment'
import { nanoid } from 'nanoid'
import App from './app'
import * as serviceWorker from './misc/serviceWorker'

window.$ = window.jQuery = require('jquery')
window._ = require('lodash')
window.moment = moment
window.shortid = shortid
window.nanoid = nanoid
window.classNames = require('classnames');

ReactDOM.render(<App />, document.getElementById('root'))

serviceWorker.unregister()
