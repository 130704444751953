import Banners from './modulos/banners';
import CategoriasProdutos from './modulos/categorias_produtos'
import Newsletter from './modulos/newsletter';
import Produtos from './modulos/produtos'
import Diferenciais from './modulos/diferenciais'
import Depoimentos from './modulos/depoimentos'
import Leads from './modulos/leads'

export default [
  Banners,
  CategoriasProdutos,
  Depoimentos,
  Diferenciais,
  Newsletter,
  Leads,
  Produtos
];
