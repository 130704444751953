import React from 'react';
import Loadable from 'react-loadable'
import { configs } from 'theme'
import config from './config'
import Loader from 'admin/painel/inc/Loader';
import store from 'admin/painel/modulos/base/redux'
import { PictureOutlined } from '@ant-design/icons';
import * as actions from 'admin/painel/modulos/base/redux/actions';

export default {
    routeProps: {
      path: `${configs.painel_path}/${config.path}`,
      component: Loadable({
        loader: () => import('admin/painel/modulos/base/Router'),
        loading: () => <Loader />
      })
    },
    Redux: store,
    Actions: actions,
    icon: <PictureOutlined />,
    name: config.title,
    slug: config.path,
    db: config.db,
    i18n: [{
      locale: "pt-BR",
      file: require('./i18n/pt.json')
    }]
};
