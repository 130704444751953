import React from 'react';
import { Trans } from 'react-i18next';
import { Tag, Space, Divider, Spin } from 'antd';
import { configs } from 'theme';
import { Link, generatePath } from 'react-router-dom';
import { sortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import getColumnSearchProps from 'admin/painel/inc/helpers/columnSearch'
import Loadable from 'react-loadable'

const DuplicityRecord = Loadable({
  loader: () => import('admin/painel/modulos/base/components/DuplicityRecord'),
  loading: () => <Spin />
})


const Delete = Loadable({ loader: () => import('admin/painel/modulos/base/pages/Delete'), loading: () => <Spin /> })

const __NAMASPACE_ = 'produtos';

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
));

const config = {
  path: __NAMASPACE_,
  full_path: `${configs.painel_path}/${__NAMASPACE_}/`,
  title: <Trans i18nKey={`${__NAMASPACE_}:MODULE`} />,
  db: __NAMASPACE_,
  fields: [
    { name: 'id', label: <Trans i18nKey={`${__NAMASPACE_}:FIELDS_01`} />, type: 'id' },
    { name: 'title', label: <Trans i18nKey={`${__NAMASPACE_}:FIELDS_02`} />, type: 'title_slug', validations: [{ required: true, message: <Trans i18nKey="common:INPUT_REQUIRED" /> }] },
    { name: 'subtitle', label: <Trans i18nKey={`${__NAMASPACE_}:FIELDS_12`} />, type: 'text', validations: [{ required: true, message: <Trans i18nKey="common:INPUT_REQUIRED" /> }] },
    { name: 'slug', label: <Trans i18nKey={`${__NAMASPACE_}:FIELDS_03`} />, type: 'slug', validations: [{ required: true, message: <Trans i18nKey="common:INPUT_REQUIRED" /> }]},
    { name: 'peso', label: 'Peso', type: 'text', validations: [{ required: true, message: <Trans i18nKey="common:INPUT_REQUIRED" /> }]},
    { name: 'text', label: 'Texto princ.', type: 'textarea', validations: [{ required: true, message: <Trans i18nKey="common:INPUT_REQUIRED" /> }]},
    { name: 'image', label: <Trans i18nKey={`${__NAMASPACE_}:FIELDS_04`} />, type: 'image', validations: [{ required: true, message: <Trans i18nKey="common:INPUT_REQUIRED" /> }]},
    { name: 'image_hover', label: <Trans i18nKey={`${__NAMASPACE_}:FIELDS_04`} />, type: 'image', validations: [{ required: true, message: <Trans i18nKey="common:INPUT_REQUIRED" /> }]},
    { name: 'label', label: <Trans i18nKey={`${__NAMASPACE_}:FIELDS_05`} />, type: 'textarea', validations: [{ required: true, message: <Trans i18nKey="common:INPUT_REQUIRED" /> }]},
    { name: 'gallery', label: <Trans i18nKey={`${__NAMASPACE_}:FIELDS_06`} />, type: 'gallery'},

    { name: 'text_icon_01', label: <Trans i18nKey={`${__NAMASPACE_}:FIELDS_05`} />, type: 'textarea'},
    { name: 'image_icon_01', label: <Trans i18nKey={`${__NAMASPACE_}:FIELDS_04`} />, type: 'image'},
    { name: 'text_icon_02', label: <Trans i18nKey={`${__NAMASPACE_}:FIELDS_05`} />, type: 'textarea'},
    { name: 'image_icon_02', label: <Trans i18nKey={`${__NAMASPACE_}:FIELDS_04`} />, type: 'image'},
    { name: 'text_icon_03', label: <Trans i18nKey={`${__NAMASPACE_}:FIELDS_05`} />, type: 'textarea'},
    { name: 'image_icon_03', label: <Trans i18nKey={`${__NAMASPACE_}:FIELDS_04`} />, type: 'image'},
    { name: 'text_icon_04', label: <Trans i18nKey={`${__NAMASPACE_}:FIELDS_05`} />, type: 'textarea'},
    { name: 'image_icon_04', label: <Trans i18nKey={`${__NAMASPACE_}:FIELDS_04`} />, type: 'image'},
    { name: 'text_icon_05', label: <Trans i18nKey={`${__NAMASPACE_}:FIELDS_05`} />, type: 'textarea'},
    { name: 'image_icon_05', label: <Trans i18nKey={`${__NAMASPACE_}:FIELDS_04`} />, type: 'image'},

    { name: 'video', label: <Trans i18nKey={`${__NAMASPACE_}:FIELDS_09`} />, type: 'repeater' },
    { name: 'file', label: <Trans i18nKey={`${__NAMASPACE_}:FIELDS_13`} />, type: 'file' },
    { name: 'categoria', label: <Trans i18nKey={`${__NAMASPACE_}:FIELDS_10`} />, type: 'select_relationship', relationship: 'categorias_produtos', validations: [{ required: true, message: <Trans i18nKey="common:INPUT_REQUIRED" /> }] },
    { name: 'relacionados', label: `Conheça também`, type: 'multiple_relationship', relationship: 'produtos', targetField: 'subtitle' },
    { name: 'is_home', label: 'Mostrar na home', type: 'switch' },
    { name: 'status', label: <Trans i18nKey={`${__NAMASPACE_}:FIELDS_11`} />, type: 'switch' },
  ],
  columns: [
    {
      title: <MenuOutlined style={{ color: '#999' }} />,
      dataIndex: 'sort',
      width: 40,
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: <Trans i18nKey={`${__NAMASPACE_}:FIELDS_02`} />,
      dataIndex: 'title',
      key: 'title',
      ellipsis: false,
      ...getColumnSearchProps('title', __NAMASPACE_)
    },
    {
      title: <Trans i18nKey={`${__NAMASPACE_}:FIELDS_12`} />,
      dataIndex: 'subtitle',
      key: 'subtitle',
      width: '180px',
      ellipsis: true,
      ...getColumnSearchProps('subtitle', __NAMASPACE_)
    },
    {
      title: `Peso`,
      dataIndex: 'peso',
      key: 'peso',
      width: '100px',
      ellipsis: true,
      ...getColumnSearchProps('peso', __NAMASPACE_)
    },
    {
      title:<Trans i18nKey={`${__NAMASPACE_}:FIELDS_11`} />,
      key: 'status',
      dataIndex: 'status',
      width: '180px',
      filters: [
        { text: <Trans i18nKey="common:ACTIVE" />, value: true },
        { text: <Trans i18nKey="common:INACTIVE" />, value: false },
      ],
      // filteredValue: filteredInfo.name || null,
      onFilter: (value, record) => record.name.includes(value),
      sorter: (a, b) => a.name.length - b.name.length,
      // sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
      render: status => (
        <>
          {status
            ? <Tag color="green">
                <Trans i18nKey="common:ACTIVE" />
              </Tag>
            : <Tag color="yellow">
              <Trans i18nKey="common:INACTIVE" />
            </Tag>
          }
        </>
      ),
    },
    {
      title: <Trans i18nKey="common:COLUMN_OPTIONS" />,
      width: '340px',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Link to={generatePath(`${configs.painel_path}/${__NAMASPACE_}/editar/:uuid`, { uuid: record.uid })}><Trans i18nKey="common:COLUMN_EDIT" /></Link>

          <DuplicityRecord values={record} />
          <Divider type="vertical" />
          <Link to={generatePath(`${configs.painel_path}/${__NAMASPACE_}/visualizar/:uuid`, { uuid: record.uid })}><Trans i18nKey="common:COLUMN_SHOW" /></Link>
          <Divider type="vertical" />
          <Delete id={record.uid} db={__NAMASPACE_} />
        </Space>
      ),
    },
  ]
}

export default config
