import { createStore, applyMiddleware, combineReducers } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension';
import 'firebase/firestore'
import thunk from 'redux-thunk'
import logger from 'redux-logger'
import modules from 'admin/painel/modulos'
import painel from 'admin/painel/inc/redux'
import { promiseMiddleware } from '@adobe/redux-saga-promise'
import { persistStore, persistReducer } from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import immutableTransform from 'redux-persist-transform-immutable'
import createCompressor from 'redux-persist-transform-compress'
import app from '../modules/app.module'

const persistConfig = {
  key: 'root',
  storage: storageSession,
  stateReconciler: hardSet,
  transforms: [
    createCompressor(),
    immutableTransform()
  ],
}

const activeTheme = require(`templates/${process.env.REACT_APP_THEME}/redux`).default;

const analytics = () => next => action => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: action.type,
    payload: action.payload,
  })

  return next(action)
}

const configureStore = (initialState = {}) => {
  const reducers = combineReducers({
    app,
    painel,
    website: activeTheme,
    ...modules.map(i => ({ [i.name]: i.Redux.reducer ?? undefined })),
  })

  const middlewares = [
    thunk,
    process.env.NODE_ENV !== 'production' && logger,
    analytics,
    promiseMiddleware
  ].filter(Boolean)

  const enhancer = composeWithDevTools(applyMiddleware(...middlewares))

  const persistedReducer = persistReducer(persistConfig, reducers)

  const combinedStore = createStore(persistedReducer, initialState, enhancer)

  return { store: combinedStore, persistor: persistStore(combinedStore) }
}

export default configureStore()
