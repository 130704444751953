import React from 'react';
import { initReactI18next } from 'react-i18next'
import Cache from 'i18next-localstorage-cache';
import LanguageDetector from 'i18next-browser-languagedetector'
import i18next from 'i18next'
import modules from 'admin/painel/modulos'
import moment from 'moment'

i18next
  .use(Cache)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'pt-BR',
    fallbackLng: 'pt-BR',
    debug: false,
    initImmediate: false,
    saveMissing: true,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    defaultTransParent: React.Fragment
  });

i18next.addResourceBundle('pt-BR', 'common', require('./i18n/pt/common.json'))
i18next.addResourceBundle('es', 'common', require('./i18n/es/common.json'))
i18next.addResourceBundle('en', 'common', require('./i18n/en/common.json'))
i18next.addResourceBundle('pt-BR', 'contatos', require('./admin/painel/globais/leads/i18n/pt.json'))

modules.map(module => module.i18n.map(locale => i18next.addResourceBundle(locale.locale, module.slug, locale.file)) )

window.i18n = i18next;

moment.defineLocale('pt-BR', {
  months : 'janeiro_fevereiro_março_abril_maio_junho_julho_agosto_setembro_outubro_novembro_dezembro'.split('_'),
  monthsShort : 'jan_fev_mar_abr_mai_jun_jul_ago_set_out_nov_dez'.split('_'),
  weekdays : 'domingo_segunda-feira_terça-feira_quarta-feira_quinta-feira_sexta-feira_sábado'.split('_'),
  weekdaysShort : 'dom_seg_ter_qua_qui_sex_sáb'.split('_'),
  weekdaysMin : 'dom_2ª_3ª_4ª_5ª_6ª_sáb'.split('_'),
  longDateFormat : {
      LT : 'HH:mm',
      L : 'DD/MM/YYYY',
      LL : 'D [de] MMMM [de] YYYY',
      LLL : 'D [de] MMMM [de] YYYY [às] LT',
      LLLL : 'dddd, D [de] MMMM [de] YYYY [às] LT'
  },
  calendar : {
      sameDay: '[Hoje às] LT',
      nextDay: '[Amanhã às] LT',
      nextWeek: 'dddd [às] LT',
      lastDay: '[Ontem às] LT',
      lastWeek () {
        return (this.day() === 0 || this.day() === 6) ?
          '[Último] dddd [às] LT' : // Saturday + Sunday
          '[Última] dddd [às] LT'; // Monday - Friday
      },
      sameElse: 'L'
  },
  relativeTime : {
      future : 'em %s',
      past : '%s atrás',
      s : 'segundos',
      m : 'um minuto',
      mm : '%d minutos',
      h : 'uma hora',
      hh : '%d horas',
      d : 'um dia',
      dd : '%d dias',
      M : 'um mês',
      MM : '%d meses',
      y : 'um ano',
      yy : '%d anos'
  },
  ordinal : '%dº'
});

window.findLocale = locale => {
  const result = window._.find(process.env.REACT_APP_LANGS.split(','), r => {
    return r.split(':')[2] === locale;
  })

  return result.split(':')[0]
}


window.findLocaleIntl = locale => {
  const target = locale

  switch(target) {
    case "br":
      return "pt_BR";
    case "uk":
      return "en";
    default:
      return target;
  }
}


export default i18next
