import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/performance'
import 'firebase/analytics'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/remote-config'

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

firebase.initializeApp(config)
firebase.analytics()

export const auth = firebase.auth()
export const storage = firebase.storage()
export const performance = firebase.performance()
export const firestore = firebase.firestore()
export const remoteConfig = firebase.remoteConfig();
export const database = firebase.database()

export default firebase
