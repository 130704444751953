import React from 'react';
import { Spin, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next'

const Loader = () => {
  const { t } = useTranslation();

  return (
    <Row justify="center" align="middle">
      <Col span={4}>
        <Spin size="large" tip={t(`common:LOADING`)} />
      </Col>
    </Row>
  );
}

export default Loader;
