import { createReducer } from 'redux-act';
import * as actions from './actions';

export default createReducer({
  [actions.setContent]: (state, payload)              => ({ ...state, content: payload }),
  [actions.setContentDefault]: (state, payload)       => ({ ...state, content_default: payload }),
  [actions.setTitle]: (state, payload)                => ({ ...state, title: payload }),
  [actions.setMenu]: (state, payload)                 => ({ ...state, currentMenuSection: payload }),
  [actions.setConfiguracoes]: (state, payload)        => ({ ...state, config: payload }),
  [actions.setConfiguracoesDefault]: (state, payload) => ({ ...state, config_default: payload }),
  [actions.setIsHome]: (state, payload)               => ({ ...state, is_home: payload }),
}, {
  content: {},
  content_default: {},
  title: "--",
  config: {},
  config_default: {},
  currentMenuSection: "home",
  is_home: false
});
